.chart-container-height {
    // height: 337px !important;
    height: auto;
    // min-height: 337px !important;
    // overflow: hidden;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;


    .ui.toggle.checkbox {
        min-height: 0 !important;
    }

    .ui.toggle.checkbox .box:before,
    .ui.toggle.checkbox label:before {
        // background-color: #F18A00;
        background: #fff linear-gradient(transparent, rgba(0, 0, 0, .05));
    }

    .ui.toggle.checkbox .box:after,
    .ui.toggle.checkbox label:after {
        width: 1.4rem !important;
        height: 1.4rem !important;
        top: -3px !important;
        background-color: #F18A00;
    }

    .ui.toggle.checkbox input:checked~.box:before,
    .ui.toggle.checkbox input:checked~label:before {
        background-color: #FFE7C8 !important;
    }

    .ui.toggle.checkbox .box:before,
    .ui.toggle.checkbox label:before {
        // width: 2.8rem !important;
        height: 1rem !important;
    }

    .ui.toggle.checkbox .box,
    .ui.toggle.checkbox label {
        min-height: 1rem !important;
    }


}

.chart-container-header {
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 20px;
    text-align: left;
    font: normal normal medium 16px/17px Quicksand;
    letter-spacing: 0px;
    color: #193256;
    opacity: 1;

    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    font-size: Quicksand;


    display: flex;
    align-items: center;
    gap: 20px;
}
.chart-container-div {
    margin-bottom: 20px;
    text-align: left;
    font: normal normal medium 16px/17px Quicksand;
    letter-spacing: 0px;
    color: #193256;
    opacity: 1;

    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    font-size: Quicksand;


    display: flex;
    align-items: center;
    gap: 20px;
}

.chart-container-content {
    flex-grow: 2;
}