$dotNavyBlue: #193256;
$dotOrrange: #f18a00;
$dotWhite: #ffffff;
$dotLightBlue: #f1f1f1;
$dotLightGrey: #f6f6f6;
$dotDarkGrey: #cbcbcb;
$DarkGrey: lightgray;
$dotSmallContainerBg: #f6f6f6;
$dotBlack: #000000;
$dotGreen: green;
$dotLightGreen: #9EFF9E;
$dotRed: #e52c2c;
$dotModalBg: #f3f7f9;
$dotModalBorder: #c2e6f8;
$dotContactBorder: #e8e8e8;
$dotMicBlue: #00afe7;
$dotRedError: #9f3a38;
// staff leave colors

// $Holiday : #FEC5BB;
// $Credit:#FCD5CE;
// $Paid:#FAE1DD;
// $Absent:#F8EDEB;
// $Regularization:#E8E8E4;
// $Privileged:#D8E2DC;
// $Maternity:#ECE4DB;
// $WeekOff:#FEC89A;

$dotGreenL: #69a305;
$dotLightGreenL: #99cf3c;
$dotYellow: #ffc000;
$dotRedL: #ff0000;
$dotblue: #00b0f0;
$dotGrey: #aeaaaa;
$dotLimeGreen: #33cc33;
$dotPink: #d60093;
$dotDarkOrange: #ff3300;
$dotBlueGreen: #0a91c7;
$dotLightBlueL: #65c4d9;