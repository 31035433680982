@import "../components/color";

.TemplateEditor {
  .formContainer {
    padding: 2% 2% 2% 2% !important;
  }
  textarea {
    resize: none !important;
  }
  .editor-container {
    padding: 30px !important;
    & .editor-image-container {
      display: inline-flex;
      margin: 10px;
      flex-wrap: wrap;
      & img {
        width: 15rem;
        height: 15rem;
        object-fit: contain;
        margin: 10px;
      }
    }
  }
  .ui.segment {
    margin-bottom: 0;
  }
  &__section {
    margin-bottom: 2rem;
    &-upload {
      margin-top: 3rem !important;
      &-container {
        width: 50% !important;
        margin: inherit auto;
        & .item.dotted {
          padding: 1rem;
        }
        & .uploads {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin-top: 10px;
          & .item {
            padding: 10px;
            margin: 10px;
            border: 1px solid rgba(black, 0.2);
            border-radius: 5px;
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            button {
              border: none;
              outline: none;
              background-color: transparent;
              padding-right: 0;
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    &-actionbutton {
      width: max-content;
      &.option {
        margin-top: 1rem;
        margin-left: 0.5em;
      }
    }
    &-titlecontainer {
      display: flex;
      flex-direction: column;
      .toplabel {
        font-size: 1em;
      }
      .text {
        font-size: 1.3em;
        color: #193256;
        font-weight: bold;
      }
    }

    .mainlabel {
      font-size: 1.3em;
      color: #193256;
      font-weight: bold;
    }
  }
  &__mainbutton {
    background-color: rgba($dotOrrange, 0.2) !important;
    padding: 1rem 0 !important;
  }
  &__actions {
    margin-top: 2rem;
    button {
      padding: 1rem 2rem !important;
      font-size: 15px !important;
      margin-right: 1rem !important;
      background-color: $dotOrrange !important;
      box-shadow: 0px 3px 6px #f18a0038 !important;
      &:last-child {
        color: white !important;
      }
      &:not(:last-child) {
        border: 1px solid $dotOrrange;
        background-color: transparent !important;
        color: $dotOrrange !important;
      }
    }
  }
  .fill-top {
    &::before {
      content: "";
      display: block;
      position: absolute;
      z-index: 1;
      border-radius: 10px 10px 0 0;
      width: 100%;
      height: 0.5rem;
      background-color: $dotOrrange;
    }
  }
  .fill-left {
    position: relative;
    &::before {
      content: "";
      display: block;
      position: absolute;
      z-index: 1;
      border-radius: 10px 0 0 10px;
      height: 100%;
      width: 0.5rem;
      background-color: $dotOrrange;
    }
  }
}
.aligned-div {
  position: absolute;
  margin-top: 200px;
  top: 0;
  right: 0;
}
.container-div{
  position: relative;
}
.margin-85{
  margin-top: 85Px;
}
