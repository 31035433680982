.invoice-flex {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
}

.invoice-right-flex {
}

.invoice-card {
  display: flex;
  background-color: $dotWhite;
  height: 30px;
  border-radius: 5px;
  padding: 5px;
  gap: 5px;
  // box-shadow: 1px 1px 1px $black;

  .ui.toggle.checkbox {
    min-height: 0 !important;
  }

  .ui.toggle.checkbox .box:before,
  .ui.toggle.checkbox label:before {
    // background-color: #F18A00;
    background: #fff linear-gradient(transparent, rgba(0, 0, 0, 0.05));
  }

  .ui.toggle.checkbox .box:after,
  .ui.toggle.checkbox label:after {
    width: 1.4rem !important;
    height: 1.4rem !important;
    top: -3px !important;
    background-color: #f18a00;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ffe7c8 !important;
  }

  .ui.toggle.checkbox .box:before,
  .ui.toggle.checkbox label:before {
    // width: 2.8rem !important;
    height: 1rem !important;
  }

  .ui.toggle.checkbox .box,
  .ui.toggle.checkbox label {
    min-height: 1rem !important;
  }
}

.ui.icon.button.invoice-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e8e8;
  border-radius: 7px;
  opacity: 1;
}

.invoices {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e8e8;
  border-radius: 7px;
  opacity: 1;
  width: 100%;
  min-height: 300px;
  gap: 1%;
  margin-top: 1%;
  text-align: left;
  font: normal normal bold 20px/25px Quicksand;
  letter-spacing: 0px;
  color: #193256;
  opacity: 1;
  font-weight: 900;
  font-size: 20px;
  line-height: 25px;
  font-family: Quicksand;
  // letter-spacing: 0px;
}
.tableWrapper {
  margin: 0 1.5rem 1.5rem 1.5rem;
  background: white;
  padding: 1.5rem;
  border-radius: 5px;
  .mb15 {
    margin-bottom: 15px;
  }
  .title {
    font-size: 18px !important;
  }
}
.tableWrapperInvoice {
  max-height: 41vh;
  overflow: auto;
}
.invoice-container {
  padding: 1.5rem 1.5rem 0 1.5rem;
}
.cusWidth30 {
  width: 50%;
}
.invoiceTopNav {
  // justify-content: flex-end;
  display: flex;
  gap: 10px;
}
.invoiceRadio {
  justify-content: flex-end;
  font-size: 20px;
  width: 100%;
  margin-right: 20px;
  display: flex;
  gap: 10px;
  .ui.toggle.checkbox .box:after,
  .ui.toggle.checkbox label:after {
    background-color: #f18a00;
  }
  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ffe7c8 !important;
  }
}
.invoiceDropdown {
  .dropdown {
    height: 40px;
    .divider {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 150px;
    }
  }
}
.invoiceTable {
  & ::-webkit-scrollbar {
    width: 4px;
  }

  // margin-top: 20px;

  &-min-height {
    min-height: 45vh;
  }

  //  max-height: 60vh !important;
  //  min-height: 60vh !important;
  //  height: 60vh !important ;
  //  overflow-y: auto;

  .ui.checkbox input:checked ~ .box:after,
  .ui.checkbox input:checked ~ label:after,
  .ui.checkbox .box:before,
  .ui.checkbox label:before {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border-radius: 0.21428571rem;
    border: 1px solid #d4d4d5;
  }

  .table-header {
    display: table;
    width: 100%;
    table-layout: fixed;
    border: none !important;

    // thead {
    tr {
      th {
        // &:first-child {
        //     width: 50px;
        // }
      }

      .ui.checkbox .box:before,
      .ui.checkbox label:before {
        background: transparent;
      }
    }

    .set-hd-size {
      &:nth-of-type(1) {
        width: 10%;
      }

      &:nth-of-type(2) {
        width: 20%;
      }

      &:nth-of-type(3) {
        width: auto;
      }

      &:nth-of-type(4) {
        width: 15%;
      }
    }

    .ui.checkbox input:checked ~ .box:before,
    .ui.checkbox input:checked ~ label:before {
      background-color: transparent !important;
      color: transparent !important;
      border: 1px solid #d4d4d5;
      border-radius: 0.21428571rem;
    }

    .ui.checkbox input:checked ~ .box:after,
    .ui.checkbox input:checked ~ label:after {
      color: #f18a00;
      background: transparent !important;
      border: 1px solid #d4d4d5;
      border-radius: 0.21428571rem;
    }
  }

  .table-body {
    &-min-height {
      height: 45vh;
    }

    &-min-height-leave {
      height: 22vh;
    }

    display: block;
    overflow: auto;

    tr {
      display: table;
      width: 100%;
      table-layout: fixed;
      border: none !important;

      td {
        border-left: none !important;
        border-right: none !important;

        // &:first-child {
        //     width: 50px;
        // }
      }
    }

    .firstRowNotification {
      width: 10%;
      border-left-color: $dotOrrange !important;
      border-width: 5x;
    }

    .secondRowNotication {
      width: 20%;
    }

    .thirdRowNotification {
      width: auto;
    }

    .fourthRowNotification {
      width: 15%;
    }

    .table-body2 {
      // max-height: 60vh !important;
      // min-height: 60vh !important;
      // height: 60vh !important;
      // overflow-y: auto;
      display: block;
      height: auto !important;
      overflow: auto;
    }

    .ui.checkbox input:checked ~ .box:before,
    .ui.checkbox input:checked ~ label:before {
      background: transparent;
      border: 1px solid #d4d4d5;
      border-radius: 0.21428571rem;
    }

    .ui.checkbox input:checked ~ .box:after,
    .ui.checkbox input:checked ~ label:after {
      color: #ffffff;
      background: #f18a00;
      border: 1px solid #d4d4d5;
      border-radius: 0.21428571rem;
    }
  }

  .table th,
  td {
    word-break: break-word !important;
    white-space: inherit;
  }

  .range-keep {
    word-break: keep-all !important;
    white-space: pre;
    overflow: hidden;
    text-overflow: unset;
  }
}
.invoiceButton {
  justify-content: flex-end;
  display: flex;
  margin-bottom: 20px;
}
.invoiceTotalDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  margin-top: 10px;
  div {
    display: flex;
    font-weight: 600;
    font-size: 16px;
    .widthFixedInvoice {
      flex-direction: column;
      min-width: 10vw;
      text-align: end;
    }
  }
}
.totalDivider {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  .divider {
    min-width: 24%;
    margin-bottom: 0px !important;
  }
}
.verticalMiddle {
  vertical-align: middle;
  .invPl0 {
    padding-left: 5px !important;
    width: -webkit-fill-available !important;
  }
}
.invoiceFlex {
  display: flex;
  flex-direction: row-reverse;
}
.invDiv {
  min-height: 1460px;
  // border: 2px solid black;
  width: 1038px;
}
.canvas_div_pdf {
  min-height: 1780px;
  width: 1038px;
  border: 2px solid black;
}
// .invDiv2 {
//   height: 1684px;
//   border: 2px solid black;
//   width: 595px;
// }

// ---------------------------------------PDF Section ---------------------------------------------

.width100 {
  width: 100%;
}
.pdfInvGrid {
  p {
    margin: 0;
    font-size: 16px;
  }
}
.pdfInvGridTax {
  margin: 30% 0% 0% 12%;
  width: 180px;
  h1 {
    margin: 0;
  }
  p {
    margin: 0;
    font-size: 16px;
  }
}
.pdfInvGridTaxSecond {
  margin: 10% 0% 0% 12%;
  width: 200px;
  h1 {
    margin: 0;
  }
  p {
    margin: 0;
    font-size: 16px;
  }
}
.pdfInvGridTaxBottom {
  margin: 0% 0% 0% 16%;
  width: 270px;
  h1 {
    margin-bottom: 10px;
  }
  p {
    // margin-top: 10 !important;
    font-size: 16px;
  }
}
.noPaddingPDF {
  padding: 0 !important;
  padding-right: 17px !important;
  // margin-bottom: 10%;
}
.imageContainerPDF {
  img {
    height: auto !important;
    width: 310px !important;
    margin-right: 100% !important;
    margin-top: 10% !important;
  }
}
.tablePDF {
  padding: 7.4% !important;
  justify-content: flex-end !important;
  th {
    background: $dotWhite !important;
    border-bottom: 1px solid $dotBlack !important;
  }
  td {
    border: none !important;
    border-bottom: 1px solid $dotDarkGrey !important;
  }
  table {
    border: none !important;
    font-size: 16px !important;
  }
}
.mt7 {
  margin-top: 7%;
}
.sixteen.wide.column .mt7 {
  margin-top: 7%;
}
.secondPDFTableHeader {
  // margin-right: 34vw;
  // margin-bottom: 0px;
  font-size: 16px;
  font-weight: 600;
  width: 100vw;
}
.tablePDFSecond {
  padding: 1% 7.5% !important;
  justify-content: flex-end !important;
  th {
    padding: 0 0.78571429em !important;
    background: $dotWhite !important;
    border-bottom: 1px solid $dotBlack !important;
  }
  td {
    padding: 0 0.78571429em !important;
    border: none !important;
    border-bottom: 1px solid $dotDarkGrey !important;
  }
  table {
    margin: 0px !important;
    border: none !important;
    font-size: 16px !important;
  }
}
.invoiceTotalDivPDF {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  padding-right: 12px;
  // margin-top: 10px;
  div {
    display: flex;
    // font-weight: 600;
    font-size: 16px;
    color: $dotBlack;
    .widthFixedInvoice {
      flex-direction: column;
      min-width: 122px;
      text-align: end;
    }
  }
}
.invoiceTotalDivPDFSecond {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  margin-right: 1.5%;
  // margin-top: 10px;
  div {
    display: flex;
    // font-weight: 600;
    font-size: 16px;
    color: $dotBlack;
    .widthFixedInvoice {
      flex-direction: column;
      min-width: 5.5vw;
      text-align: end;
    }
  }
}
.invoiceTotalDivPDFBottom {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0px;
  margin-right: 34px;
  // margin-top: 10px;
  div {
    display: flex;
    // font-weight: 600;
    font-size: 14px;
    color: $dotBlack;
    .widthFixedInvoice {
      flex-direction: column;
      min-width: 234px;
      text-align: end;
    }
  }
}
.bottomAddressPDF {
  color: $dotBlack !important;
  margin-left: 6%;
  .font14PDF {
    font-size: 18px;
    margin: 0 !important;
  }
  .font12PDF {
    font-size: 16px;
    line-height: 1.5;
  }
}
.scissors {
  background-size: 20px;
  i {
    position: absolute;
    margin-top: 12px;
    margin-left: 10px;
  }
  // transform: rotate(180deg);
  height: 43px; /* image height */
  width: 90%;
  margin: auto auto;
  // background-image: url('http://i.stack.imgur.com/cXciH.png');
  background-repeat: no-repeat;
  background-position: right;
  position: relative;
}
.scissors div {
  position: relative;
  top: 50%;
  border-top: 2px dashed black;
  margin-top: -1px;
}
.bottomTextPDF {
  margin-left: 6%;
  margin-top: 1%;
  font-size: 12px;
  color: $dotBlack;
}
.font6PDF {
  font-size: 12px !important;
}
.linePDFBk {
  width: 95%;
  margin-left: 5%;
  margin-top: 24px;
  border: none;
  border-top: 1px solid $dotBlack !important;
}
.linePDFAmountBk {
  width: 360px;
  margin: 0px;
  border: none;
  border-top: 1px solid $dotDarkGrey !important;
}
.linePDFAmountBkBK {
  width: 235px;
  margin: 0px;
  border: none;
  border-top: 1px solid $dotBlack !important;
}
.toolTipTrigger:hover .tooltiptext2 {
  visibility: visible;
}
.toolTipTrigger {
  position: relative !important;
}
.tooltiptext2 {
  visibility: hidden;
  width: 120px;
  background-color: $dotBlack;
  opacity: 0.5;
  color: $dotWhite;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute !important;
  z-index: 1;
  top: 24px;
  left: -38px;
}
.displayFlexSla {
  display: flex;
  min-width: 64px;
  gap: 10px;
}
// .ModalSlaTable{

// }
.outerDivAttachment {
  display: flex;
  .stretched {
    padding: 0 !important;
  }
  .icon {
    padding: 0px 10px 0px 0px;
  }
}
.addDetails {
  display: flex;
  min-width: 74px;
  .plus {
    margin: 0px !important;
  }
  .trash {
    margin-top: 7px;
    color: $dotRed;
    cursor: pointer;
  }
}
.attachmentMainDiv {
  width: 100% !important;
}

.createInvoiceDateDiv {
  float: left;
  padding-bottom: 8px;
  display: flex;
  gap: 2%;
  position: relative;
  width: 100%;
  .ui.form {
    width: 100%;
    .formGroup {
      width: 100%;
      div {
        width: 100%;
      }
      button {
        width: 100%;
      }
      .multiCheckBox button {
        padding: 0.6em 1em 0.5em 1em !important;
      }
    }
  }
}
.inv-width{
  min-width: fit-content !important;
}

.masterCurrencyModal{
  .ui.input>input{
  min-width: 125% !important;
  }
}
.IBANwidth{
    margin-right:20% !important;
}
.m-0{
  margin:0px;
}
.sortCode{
  margin-right: 15% !important;
}