.date-range {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000017;
    border-radius: 6px;
    opacity: 1;
    min-width: 20%;

    .ui {
        &.icon {
            &.input {
                width: 100%;
            }
        }
    }
}

