.title {
  text-align: left;
  font: normal normal bold 28px/32px Quicksand;
  letter-spacing: 0px;
  color: #193256;
  opacity: 1;
  // margin-bottom: 15px;
}

.ui.inverted.menu {
  background-color: #ffffff;
  // height: 75px;
  border-radius: 0;
}

.ui.inverted.side_bar {
  background: #193256 !important;
  border-radius: 0;
  width: 220px !important;
  .item {
    img {
      margin-right: 1em;
    }

    &.logo {
      .ui.small.image,
      .ui.small.images .image,
      .ui.small.images img,
      .ui.small.images svg {
        width: 120px;
        height: auto;
        font-size: 0.92857143rem;
      }
      .ui.centered.image,
      .ui.centered.images {
        margin-left: auto;
        margin-right: auto !important;
      }
    }
  }
}

.ui.visible.wide.left.sidebar ~ .fixed,
.ui.visible.wide.left.sidebar ~ .pusher {
  transform: translate3d(220px, 0, 0) !important;

  // @media only screen and (max-width: 768px) {
  @include device-sm {
    /*Tablets [601px -> 1200px]*/
    transform: translate3d(0, 0, 0) !important;
  }
}

.pusher-max-width {
  width: calc(100vw - 220px) !important;
  max-width: calc(100vw - 220px) !important;

  // @media only screen and (max-width: 768px) {
  @include device-sm {
    width: calc(100vw) !important;
    max-width: calc(100vw) !important;
  }
}

.vertical .labeled .icon .ui .left .wide .visible .sidebar .menu {
  height: 100vh;
  min-height: 100%;
  background-color: #193256 !important;
}

.ui.menu .item:before {
  background: none !important;
  text-align: left !important;
}

.ui.icon.menu .item {
  text-align: left !important;
  height: 3rem !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}

.ui.floating.label.notification {
  // top: 2.1em !important;
  top: 1.1em !important;
  left: 80% !important;

  width: 0.5em !important;
  height: 0.5em !important;
  padding: 0.2em !important;
  margin: 0 0 0 -0.5em !important;

  // top: 1.5em !important;
  // left: 60% !important;
  // @media only screen and (max-width: 768px) {
  @include device-sm {
    top: 0.8em !important;
    left: 90% !important;
  }
}

.notification-box {
  overflow-y: auto;
  max-height: 430px !important;
  // max-width: 100% !important;
  padding: 0.833em 0em !important;
  // height: 450px !important;
  &::-webkit-scrollbar {
    width: 4px;
  }
  .notificationHeader {
    padding-top: 0.3rem !important;
    padding-bottom: 0.2rem !important;
  }
}

a.side-bar-menu {
  &:hover {
    background-color: rgba(#f18a00, 0.7) !important;
    transition: all 0.3s ease-in-out;
  }

  &:active {
    background-color: rgba(#f18a00, 0.7) !important;
    transition: all 1s ease-in-out;
  }

  &.item {
    & #icon-right {
      margin-left: auto !important;
    }
  }
}

.ui.labeled.icon.menu .side-bar-menu {
  margin: 1% 3%;
  padding: 5%;
  border-radius: 5px !important;
  transition: all 0.3s ease-in-out;
  text-align: left;
  // font: normal normal medium 14px/18px Quicksand;
  font-size: 100%;
  line-height: 18px;
  font-weight: 500;
  font-family: Quicksand;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.notification-btn {
  border-radius: 8px !important;
  // &:hover{
  //     background-color: transparent !important;
  // }
  background: #f6f6f6 !important;

  display: flex;
  align-items: center;
  // @media only screen and (max-width: 768px) {

  @include device-sm {
    padding: 0.385714em 0.6em 0.385714em !important;
  }
}

.ui.active.notification-btn:active,
.ui.notification-btn:active {
  background-color: #e0e1e2 !important;
}

.icon .pointer {
  cursor: pointer !important;
  pointer-events: painted !important;
}

.icon-color {
  color: rgba(#193256, 0.6);
}

.nav_bar {
  .ui.menu .item {
    padding: 0.92857143em 0.14285714em !important;
  }
  .right.menu {
    .item {
      padding: 0.328571em 0.6em !important;
    }
  }
  .item {
    .button {
      position: relative;
      top: 0;
      margin: 0 0 !important;
      padding-bottom: 0.2em !important;
      padding-top: 0.2em !important;
      padding: 0.2em;
      font-size: 1em;
    }
  }
}
.marketingIcon {
  width: auto !important;
}
.marketingDown {
  padding-left: 32% !important;
}
.hrDown {
  padding-left: 60% !important;
}
// .dropDownSideBar{
//     transition-duration: 1s !important;
// }
// .side_bar.item{
//     height: 1rem !important;
// }

@include device-smm {
  .ui.wide.left.sidebar,
  .ui.wide.right.sidebar {
    width: 220px !important;
  }
}

// .notification-btn{
//     // display: flex;
//     // .icon-color{
//     //     padding-top: 8px;
//     //     font-size: 10px !important;
//     // }
// }
