@import "./color.scss";
@import "../base/mixins";
$xs-width: 575px;
.dot-card-leave {
    // .dot-card {
    box-shadow: 0px 3px 6px #00000017 !important;
    // width: auto !important;
    height: auto;
    // width: 100% !important;
    margin: 0 !important;
    @include  device-md {
        width: 100% !important;
    }
    @include  device-sm {
        width: 100% !important;
    }
    background-color: $dotWhite;
    // }
    position: relative;

    .card-header {
        text-align: left;
        font: normal normal medium 14px/18px QuicksandMedium;
        letter-spacing: 0px;
        color: #193256 !important;
        opacity: 1;
        font-weight: 600 !important;
        // font-size: 18px !important;
        font-size: 100% !important;
        line-height: 24px !important;
        font-family: QuicksandMedium !important;
        display: flex;

        .approve {
            float: right;
            display: flex;
            align-items: center;
            gap: 5px;
            color: $dotOrrange;
        }

        .reject {
            float: right;
            display: flex;
            align-items: center;
            gap: 5px;
            color: $dotRed;
        }

        .pending {
            float: right;
            display: flex;
            align-items: center;
            gap: 5px;
        }
    }

    .card-description {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        min-height: 165px !important;

        // img {
        //     width: 48px !important;
        //     height: 48px !important;
        // }

        img {
            width: 25% !important;
            height: 25% !important;
        }

        .card-value {
            text-align: left;
            font: normal normal bold 30px/30px Quicksand;
            font-weight: bold !important;
            font-size: 30px !important;
            line-height: 30px !important;
            font-family: Quicksand !important;
            letter-spacing: 0px;
            color: #193256;
            opacity: 1;
        }
    }

    .card-description-duration {
        .title {
            text-align: left;
            font: normal normal medium 12px/15px Quicksand;
            font-size: 100%;
            line-height: 12px;
            font-family: Quicksand;
            font-weight: 600;
        }

        .date {
            text-align: right;
            color: $dotOrrange;
            font-size: 12px;
            line-height: 12px;
            font-family: Quicksand;
            font-weight: 600;
        }
    }

    .appraisalCard {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 48px !important;
            height: 48px !important;
        }

        .card-value {
            text-align: left;
            font: normal normal bold 30px/30px Quicksand;
            font-weight: bold !important;
            font-size: 30px !important;
            line-height: 30px !important;
            font-family: Quicksand !important;
            letter-spacing: 0px;
            color: #193256;
            opacity: 1;
        }
    }
}

.data-card-l {
    justify-content: center;
    align-items: center;
    cursor: pointer;
    // height: 120px;
    // max-height: 120px;
    width: 100%;
    max-width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 7px 13px #00000008;
    border: 1px solid #ececec;
    border-radius: 6px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
    // gap: 10px;

    .total {
        font-size: 28px;
        font-weight: bold;
        font-family: Quicksand;
        color: $dotOrrange;
        margin-bottom: 10px;
    }

    .type {
        font-size: 12px;
        font-weight: 600;
        font-family: Quicksand;
        color: $dotNavyBlue;
        line-height: normal;
    }

    .closing {
        font-size: 12px;
        font-weight: 600;
        font-family: Quicksand;
        color: $dotDarkGrey;
        line-height: normal;
    }
}

.user-key-milestone {
    background: #efefef 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    min-height: 200px;
    padding: 1%;
}

.payslip-dropdown {
    font-size: 20px;

    .menu {
        .item {
            .text {
                font-size: 20px !important;
            }
        }
    }
}

.payslip-list {
    border-radius: 10px !important;
    .month {
        text-align: left;
        font: normal normal medium 14px/18px Quicksand;
        color: $dotNavyBlue;
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        font-family: Quicksand;
    }

    .salary {
        text-align: center;
        font: normal normal medium 16px/17px Quicksand;
        color: $dotNavyBlue;
        font-size: 16px;
        line-height: 18px;
        font-weight: 700;
        font-family: Quicksand;
    }

    .detail {
        text-align: right;
        font: normal normal medium 14px/18px Quicksand;

        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        font-family: Quicksand;
        text-decoration: underline;
        color: $dotOrrange;
        cursor: pointer;
    }
}

.apply-leave {
    position: relative;
    .ui.form .fields > .field {
        padding-left: 0 !important;
    }

    &-form {
        z-index: 9000090000;
        position: absolute;
    }
}

.leave-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
    gap: 15px;
    // flex-wrap: wrap;
  
}


.apply-leave-form{
    .formGroup input, textarea {
        line-height: 100% !important;
        font-size: 100% !important;
        color: $dotNavyBlue !important;
        font-family: Quicksand !important;
        font-weight: 500 !important;
        padding-left: .5em !important;
    }
    .apply-leave-attachment{
        font-weight: 600 !important;
        line-height: 100% !important;
        font-size: 100% !important;
        color: $dotOrrange !important;
        font-family: Quicksand !important;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
}

.performance-report-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
}
.appraisals-report-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
}
.leave-attachment{
    font-size: 1.8em !important;
    color: $dotOrrange;
    
}
.apply-leave-button{
    border: none;
    background: none;
    outline: none;
    font-size: small;
}
.leaves-color{
    color: #193256 !important;
}
.margin-right{
    margin-right: 20px !important;
}
.margin-left{  
    margin-left: 20px !important;
}
.button-margin{
    width: 18% !important;
}
.button-margin-modal{
    width: 50% !important;
}
.line-height{
    margin-left: 3px !important;
    margin-right: 3px !important;
    align-content: center !important;
    opacity: 50%;
}
.leaveForm{
    margin-right: 40PX;
    margin-left: 40px;
}
.credit-hw{
    height: 514px;
    width: 712px;
}

.width{
    width: 50% !important;
}
.btn-width{
    width:auto !important;
    // font-size: 12px;
}

@include device-sm{
    .width{
            width: fit-content !important;
            font-size: 12px !important;
           }
    }
@include device-sm{
    .btn-width{
        width: fit-content !important;
        font-size: 12px !important;
           
    }
}
@include device-sm{
    .margin-side{
        margin-left: 20px !important;
        margin-right: 20px !important;
    }
}
.option-width{
    width: 100% !important;;
}
@include device-sm{
    .option-width{
       width:75% !important;
    }
}
@include device-sm{
    .cal-width{
       font-size: 12px !important;
    }
}
@include device-sm{
    .btnCustom{
       min-width: auto !important;
    }
}
@include device-sm{
    .title-size{
       font-size: larger !important;
    }
}
@include device-sm{
    .subtitle{
       font-size: 15px !important;
    }
}
@include device-sm{
    .total{
       font-size: 14px !important;
    }
}
@include device-sm{
    .score-size{
       font-size: 10px !important;
       height: 75px!important;
       width: 87px!important;
    }
}
.questionWidth{
    width: 100%;
}
@include device-sm{
    .questionWidth{
      min-width: 100%;
    }
}
@include device-sm{
    .invoiceTitle{
        font-size: 14px !important;
        
    }
}
@include device-sm{
    .marginTitle span:last-of-type{
        display: block;
    }
}
@include device-sm{
    .mr-left{
        margin-left: 10px !important;
    }
}
@include device-sm{
    .mr-right{
        margin-right: 10px !important;
    }
}
.menuWidth{
    width:30% !important;
}
@include device-sm{
    .menuWidth{
        max-width:auto !important;
        min-block-size: 20% !important;
        margin-left: 0px !important;
    }
}
.checkWidth{
    width: 100% !important;
    padding-right: 10px;
}
 .yearDrop{
    width:300px !important;
    margin-left: 300px !important;
 } 
 .align{
     float: right !important;
 }
 .graphFont{
    font-size: medium !important;
    font-weight: bold;
    color: #193256 !important;
    display: flex;
    gap: 20px;
 }
 .graphText{
    font-size: small !important;
    font-weight: bold;
    color: #193256 !important;
 }
 .graph{
    font-size: medium !important;
    font-weight: bold !important;
    color: #193256 !important;
    float: left;
 }
 .declare{
    font-size: medium !important;
    color: #193256 !important;
    font-weight: 500 !important;
    position: relative;
    text-align: center !important; 
    margin-top: 3px !important;
    
 }
 .titleDeclare{
    font-size: large !important;
    font-weight: bold !important;
    color: #193256 !important;
    position: relative;
    text-align: center; 
 
 }
 .declareForm{
     font-size: medium !important;
     color: #193256 !important;
     font-weight: bold !important;
     position: relative;
     text-align: right;
 }
 .declareLeft{
    font-size: medium !important;
    color: #193256 !important;
    font-weight: bold !important;
    position: relative;
    text-align: left;
 }
 .declareFont{
    font-size: small !important;
    color: #193256 !important;
    position: relative;
    text-align: left;
 }
 .declareCenter{
    font-size: medium !important;
    color: #193256 !important;
    font-weight: bold !important;
    position: relative;
    text-align: center;
 }
 .declareMedium{
    font-size: medium !important;
    color: #193256 !important;
   font-weight: 500 !important;
    position: relative;
    text-align: left;
 }
 .input-text{
     border: none !important;
     min-height: 10px;
 }
 .widthText{
     width:40% !important;
 }

 .textDecoration{
    width: 30% !important;
    text-align: center;
    border:#ffffff!important;
 }
 .textDec{
    width: 20% !important;
    border-bottom: 4 !important;
    border-top: 5px !important;
    border-left: 4px!important;
    border-right:4px !important;
 }
 .textAreaWidth{
     max-width: 40% !important;
 }
.height{
    height: 150px;
}
.colorStyle{
    background-color: rgb(251, 11, 11) !important;
    opacity: 35%;
}
.fill-left-card {
    position: relative;
    &::before {
      content: "";
      display: block;
      position: absolute;
      z-index: 1;
      border-radius: 10px 0 0 10px;
      height: 100%;
      width: 0.5rem;
      background-color: $dotOrrange;
    }
  }
.fill-left-small {
    position: relative;
    width: 90% !important;
    justify-content: center;
    &::before {
      content: "";
      display: block;
      position: absolute;
      z-index: 1;
      border-radius: 10px 0 0 10px;
      height: 100%;
      width: 0.5rem;
      background-color: $dotOrrange;
    }
  }
  .fill-center-card {
        &::before {
          content: "";
          display: block;
          position: absolute;
          z-index: 1;
          border-radius: 10px 10px 0 0;
          width: 100%;
          height: 0.5rem;
          background-color: $dotOrrange;
        
    }
  }
.align-card {
    align-items: flex-start;
    font-size: 16px;
    text-align: left !important;
      display: block;
      height: 150%;
      width: 100%;
      background-color: gray;
  }
.align-card-orange {
    align-items: flex-start !important;
    font-size: 16px;
    text-align: center !important;
    margin-left: 0% !important;
    
      height: 150%;
      width: 100%;
      background-color: $dotOrrange !important;
  }
  .align-orange {
    // align-items: flex-start !important;
    font-size: 16px;
    text-align: center !important;
    margin-left: 0% !important;
    
      height: fit-content;
      min-width: 100%;
      background-color: #FCE7CB !important;
  }
  .fontColor{
      color: $dotOrrange;
  }
  .fontColorWhite{
      font-size: medium !important;
    color: white !important;
  }
  .dividerCol{
    opacity: 75;
    width: 3px;
    margin: 6px 0;
    position: relative;
    border-radius: 10px !important;
    background: $dotOrrange !important;
    color:$dotOrrange !important ;
  }
  .segment{
      border-radius: 10px !important;
  }
  .segment-visible{
      overflow: visible !important;
  }
  .overflowInherit{
      overflow: inherit !important;
  }
  .ml-left{
      margin-left: 10% !important;
  }
  .check-margin{
      margin-left: 6% !important;
  }
  .check-margin5{
    margin-left: 5% !important;
}
.w-50 {
    width: 50% !important;
  }
.w-4 {
    width: 45% !important;
  }
  .overflowAdd{
      overflow: hidden;
  }
  .css-1wy0on6>div>svg{
        display: none;
        height: 0px !important;
      }
.css-1wy0on6{
    height: 0px !important;
}
svg:not(:root) {
    overflow: visible;
}

.css-1hwfws3{
    margin-left: -7px;
}
.css-1r4vtzz{
    margin-top: 16px !important;
}
.colorBack{
    text-decoration-line: underline;
    text-decoration-color: $dotNavyBlue !important;
}
.apply-leave{
    z-index: 999 !important;
}
.applyDrop{
    z-index: 999 !important;
}
.widthRea{
    max-width: 200% !important;
}
.mtInv{
    margin-top: 15px !important;
}
.customExportDiv{
    width: 140px;
    height: 38px;
    border: 1px solid #db2828;
    border-radius: 5px;
    color: #db2828;
    padding-left: 25px;
    padding-top: 6px;
    padding-bottom: 5px;
    font-size: 15px;
    // margin-left: 1285px;
    margin-bottom: 7px;
}
.mlexp{
    padding-bottom: 2px;
    margin-left: 29px !important;
}
.modalHeight{
    height: 100vh;
  }
  .segmentClass{
    overflow: hidden;
    border-radius: 10px ;
}
.creditHeight{
    height: 50vh
}
.keyMilestoneDelete{
    align-self : center;
}
.text-form-width{
    .formGroup input, textarea {
        width: 100% ;
        padding: 1%;

    }
}
.mt-button{
    margin-top: 10% !important;
}
.video-title {
    font-size: 18px;
    font-weight: bold;
    color: #333; 
    margin-left: 10px;
    margin-top: 8px; 
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    align-content: center;
    text-overflow: ellipsis;
  }
  .video-title-main{
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-top: 8px; 
    text-align: center;
    align-content: center;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 10px !important;
  }
  .kris-video{
    margin:10px;
    display: flex;
    flex-wrap:wrap !important;
    justify-content:center;
    align-items:center;
    border-radius: 15px;
  }
  .outer_video{
    // flex-basis: 0%;
    margin: 0 10px;
    margin-bottom: 20px;

  }
.align-video{
    cursor: pointer;
    border: 30 round;
    width: 300px;
    height: 180px;
    border-radius: 15px;
    border-top-left-radius: 30px;
border-top-right-radius: 30px;
}


  
  






    

