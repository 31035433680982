@import "./color.scss";
/* -------------------------------------------------------form CSS -------------------------------------------------- */

// .formContainer {
//   margin: 15px 0px 15px 0px;
// }

.dotted {
  border: 2px dashed $dotDarkGrey !important;
  border-radius: 10px;
  background-color: $dotLightGrey !important;
  // padding: 15px;
  &-nodot {
    border: 1px solid $dotDarkGrey !important;
    min-height: 130px;
  }
}

.topMarginInput {
  margin-top: 16px !important;
}

.topMarginInputCustom {
  div {
    margin-top: 16px !important;
  }
}

.topMarginInput .dropdown {
  min-width: 0 !important;
}

.formGroup {
  // margin-top: 20px !important;
  font-size: 100% !important;
  color: $dotNavyBlue !important;
  font-family: Quicksand !important;
  font-weight: 500 !important;
}

.formGroup input,
textarea {
  padding-left: 30px !important;
}

.formGroup .dropdown {
  padding-left: 30px !important;
  color: $dotNavyBlue !important;
}

.formGroup input,
textarea {
  line-height: 100% !important;
  font-size: 100% !important;
  color: $dotNavyBlue !important;
  font-family: Quicksand !important;
  font-weight: 500 !important;
  padding-left: 30px !important;
}

.marginLeftForm {
  margin-left: 1.2% !important;
}

.cusTitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 10px;

  .title {
    font-size: 28px !important;
    font-family: QuicksandBold !important;
  }
}
.timekeepingDiv {
  margin: 1% 1% 1% 3% !important;
}
.sideMargin {
  margin: 3% !important;
}
.right_scroll {
  overflow-y: scroll;
  max-height: 72vh;
  direction: rtl;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.right_scroll::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.scroll_item {
  direction: ltr;
}
.inlineLabel input {
  padding-left: 30px !important;
}
.labelPadding {
  .dropdown {
    padding-left: 30px !important;
  }
}

.inlineLabel label {
  transform: translate(20px, -10px);
  // margin: -12px 0px 0px 20px !important;
  padding: 0px 5px 0px;
  color: $dotOrrange !important;
  background-color: $dotWhite !important;
  margin: auto;
  z-index: 2 !important;
  position: absolute;
  font-size: 80% !important;
}
.inlineLabelSlaForm label {
  transform: translate(20px, -10px);
  // margin: -12px 0px 0px 20px !important;
  padding: 0px 5px 0px;
  color: $dotOrrange !important;
  background-color: $dotWhite !important;
  margin: auto;
  z-index: 1 !important;
  position: absolute;
  font-size: 80% !important;
}
.inlineLabelSla label {
  transform: translate(20px, -10px);
  // margin: -12px 0px 0px 20px !important;
  font-weight: 700;
  padding: 0px 5px 0px;
  color: $dotOrrange !important;
  background-color: $dotWhite !important;
  margin: auto;
  z-index: 2 !important;
  position: absolute;
  font-size: 80% !important;
}
.marginBottom28 {
  margin-bottom: 28px !important;
}
.textStyle input {
  background: #efefef !important;
}

.formText {
  padding-top: 15px;
}

.formContainer {
  padding: 2% 12% 2% 12% !important;
  @include device-sm {
    padding: 0 !important;
  }
}

.statementContainer {
  padding: 15px !important;
  /* margin: 15px 0px 0px 15px !important; */
  border: 2px dashed $dotDarkGrey !important;
  border-radius: 10px;
  background-color: $dotLightGrey !important;
}

.uploadSegment {
  margin-top: 15px;
  border: 3px dashed $dotDarkGrey !important;
}

.statementContainer p {
  font-size: 16px;
  font-weight: 800;
  color: $dotNavyBlue !important;
}

.dotButton {
  //  padding: 14px 26px 14px 26px !important;
  min-height: 40px !important;
  min-width: 180px;
  font-size: 100% !important;
  background-color: $dotOrrange !important;
  color: $dotWhite !important;
  &.outlined {
    background-color: $dotWhite !important;
    color: $dotOrrange !important;
    border: 2px solid $dotOrrange !important;
  }
}
.dotButtonReportExp {
  //  padding: 14px 26px 14px 26px !important;
  position: absolute;
  bottom: 34px;
  min-height: 40px !important;
  min-width: 180px;
  font-size: 100% !important;
  background-color: $dotOrrange !important;
  color: $dotWhite !important;
  &.outlined {
    background-color: $dotWhite !important;
    color: $dotOrrange !important;
    border: 2px solid $dotOrrange !important;
  }
}
.dotButton {
  //  padding: 14px 26px 14px 26px !important;
  min-height: 40px !important;
  min-width: 180px;
  font-size: 100% !important;
  background-color: $dotOrrange !important;
  color: $dotWhite !important;
  &.outlined {
    background-color: $dotWhite !important;
    color: $dotOrrange !important;
    border: 2px solid $dotOrrange !important;
  }
}
.dotButtonDisabled {
  //  padding: 14px 26px 14px 26px !important;
  min-height: 40px !important;
  min-width: 180px;
  font-size: 100% !important;
  background-color: $dotOrrange !important;
  color: $dotWhite !important;
  &.outlined {
    background-color: $dotWhite !important;
    color: $dotOrrange !important;
    border: 2px solid $dotOrrange !important;
  }
  pointer-events: none;
  cursor: not-allowed;
}
.disabledForce {
  pointer-events: none;
  cursor: not-allowed;
}
.dotButtonEnquiry {
  //  padding: 14px 26px 14px 26px !important;
  min-height: 40px !important;
  min-width: 160px !important;
  font-size: 100% !important;
  background-color: $dotOrrange !important;
  color: $dotWhite !important;
}
.dotButtonEnquiryInverted {
  //  padding: 14px 26px 14px 26px !important;
  min-height: 40px !important;
  min-width: 160px !important;
  font-size: 100% !important;
  background-color: $dotWhite !important;
  color: $dotOrrange !important;
  border: 2px solid $dotOrrange !important;
}
.uploadButton {
  min-height: 42px !important;
  min-width: 180px !important;
  font-size: 100% !important;
  background-color: $dotOrrange !important;
  color: $dotWhite !important;
}
.dotButtonInverted {
  //  padding: 14px 26px 14px 26px !important;
  min-height: 42px !important;
  font-size: 100% !important;
  background-color: $dotWhite !important;
  color: $dotOrrange !important;
  border: 2px solid $dotOrrange !important;
}

.dotButtonTransparent {
  //  padding: 14px 26px 14px 26px !important;
  min-height: 42px !important;
  font-size: 100% !important;
  background-color: transparent !important;
  color: $dotOrrange !important;
}
.statementContainer .grid {
  margin-top: 15px !important;
}

.dottedLine {
  // margin-top: 15px;
  margin-bottom: 15px;
  margin-left: -8%;
  margin-right: -8%;
  border-bottom: 2px dashed $dotDarkGrey;
}

.Line {
  margin-top: 15px;
  margin-left: -16%;
  margin-right: -16%;
  margin-bottom: 15px;
  // margin-left: -50%;
  // margin-right: -50%;
  border-bottom: 2px solid $dotDarkGrey;
}

// .ui.icon.input>i.icon {
//   // top: 18% !important;
// }

// .dateInput {
//   margin-top: 22px !important;
// }

.calendar {
  color: #f18a00 !important;
}

.spaceLeft {
  margin-left: 15px !important;
}

// label {
//   // color: $dotOrrange !important;
//   // margin: auto;
// }

.iconOrange {
  color: $dotOrrange !important;
}

.ui .cus-input,
.cus-textarea {
  color: $dotNavyBlue !important;
  font-family: Quicksand !important;
  font-weight: 500 !important;
  font-size: 100% !important;
  padding-left: 30px !important;
}

.ui cus-input {
  margin-top: 20px !important;
}

.floatLabel label {
  margin: 16px 0px 0px 24px !important;
  padding: 0px 5px 0px;
  background-color: white;
  color: $dotDarkGrey !important;
  margin: auto;
  z-index: 2 !important;
  position: absolute;
  font-size: 100% !important;
}

.tempClass label {
  margin: 16px 0px 0px 24px !important;
  padding: 0px 5px 0px;
  background-color: white;
  color: $dotDarkGrey !important;
  margin: auto;
  z-index: 2 !important;
  position: absolute;
  font-size: 100% !important;
}
.errorLabel {
  bottom: 12px !important;
}
.errorLabel::before {
  z-index: 1 !important;
}
// .floatLabel:focus-within label {
//   -webkit-animation: floatingLabel 3s forwards; /* Safari 4.0 - 8.0 */
//   animation: floatingLabel 3s forwards;
//   animation-fill-mode: forwards;
// }

// @keyframes floatingLabel  {
//   0%   { transform:scale(0.80); opacity:0.0; left:0}
//   //  50%  { transform:scale(1.2); opacity:0.5; left:100px}
//    100% { transform:scale(0.80); opacity:1.0; left:200px}
// }
.myTest {
  background-color: red !important;
}

.floatLabel label {
  transform-origin: top left !important;
  transition: all 0.2s ease-out !important;
  animation-fill-mode: forwards;
  transform: translate(-4px, -22px) scale(0.8) !important;
  color: $dotOrrange !important;
}

// .floatLabel:focus-within label {

// }

.labelUpload {
  margin: -12px 0px 0px 20px !important;
  padding: 0.5833em 0.333em !important;
  color: $dotNavyBlue !important;
  // background-color: white !important;
  background: linear-gradient(
    180deg,
    $dotWhite 50%,
    $dotLightGrey 50%
  ) !important;
  margin: auto;
  z-index: 2 !important;
  position: absolute;
  font-size: 80% !important;
}

.labelEditor {
  margin: -12px 0px 0px 20px !important;
  padding: 0.5833em 0.333em !important;
  color: $dotOrrange !important;
  background: linear-gradient(
    180deg,
    $dotWhite 50%,
    $dotLightGrey 50%
  ) !important;
  margin: auto;
  z-index: 2 !important;
  position: absolute;
  font-size: 80% !important;
}

.dtls-innr .rdw-editor-wrapper {
  padding: 10px;
}
.cloudUpload {
  padding: 0px !important;
  p {
    line-height: 10px;
  }
}
.cloudUpload:first-child {
  padding-right: 7px !important;
}
.cloudUpload:nth-child(2) {
  padding-left: 7px !important;
}

.icon.ui.icon.input > i.icon {
  color: $dotOrrange;
}

.rejectButton {
  //  padding: 14px 26px 14px 26px !important;
  min-height: 42px !important;
  min-width: 180px !important;
  font-size: 100% !important;
  background-color: $dotWhite !important;
  // border-color: $dotOrrange !important;
  border: 2px solid $dotOrrange !important;
  color: $dotOrrange !important;
  margin-right: 10px !important;
}

.staffDateLabel {
  font-family: Quicksand !important;
  font-weight: medium !important;
  font-size: 100% !important;
}

.subsection {
  font-size: 100% !important;
  font-weight: bold !important;
  color: #f18a00;
}

.cvUpload {
  width: 50% !important;
  border: 3px dashed $dotDarkGrey !important;
  border-radius: 10px;
  background-color: $dotLightGrey !important;
  padding: 15px;
}

// .floatDescription div label {
//   position: absolute !important;
//   // top: 10px !important;
//   left: 0 !important;
//   color: #999 !important;
//   transition: 0.5s !important;
//   pointer-events: none !important;
// }

// .floatDescription textarea:focus~label,
// .floatDescription textarea:valid~label {
//   top: -12px !important;
//   left: 0 !important;
//   color: red !important;
// }

// .floatDescription textarea:focus,
// .floatDescription textarea:valid {
//   border-bottom: 2px solid red ;
// }

.box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 200px;

  background: #fff;
  padding: 40px;
  border: 1px solid red;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.box input {
  padding: 10px 0;
  margin-bottom: 30px;
}

.box input {
  width: 100%;
  box-sizing: border-box;
  box-shadow: none;
  outline: none;
  border: none;
  border-bottom: 2px solid #999;
}

.box form div {
  position: relative;
}

.box form div label {
  position: absolute;
  top: 10px;
  left: 0;
  color: #999;
  transition: 0.5s;
  pointer-events: none;
}

.box input:focus ~ label,
.box input:valid ~ label {
  top: -12px;
  left: 0;
  color: red;
}

.box input:focus,
.box input:valid {
  border-bottom: 2px solid red;
}
.topBottomMarginNone:not(.row) {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

.topBottomMarginNone {
  .ui.segment {
    padding: 0 !important;
  }
}
.marginBottomNone {
  margin-bottom: 0 !important;
}
.paddingBottomNone {
  padding-bottom: 0 !important;
}
.buttonDiv {
  height: fit-content;
  display: flex !important;
  button {
    margin-left: auto !important;
  }
}
.buttonDivAccess {
  height: fit-content;
  display: flex !important;
  button {
    margin-left: auto !important;
  }
  position: absolute;
  right: 22px;
  bottom: 22px;
}
.attachmentDiv {
  display: flex !important;
  img {
    min-height: 40px !important;
  }
  .attachmentDetails {
    p {
      font-family: QuicksandMedium !important;
      font-weight: 500;
      color: $dotNavyBlue !important;
      margin: 0 0 0.1em !important;
    }
    margin-left: 10px;
  }
}
.readOnly .input input {
  background-color: $dotLightGrey;
  color: $dotNavyBlue !important;
}
.radioGrid {
  display: contents !important;
}
.paddingnone {
  padding: 0 !important;
}
.cloudUpload {
  margin-bottom: 20px !important;
}
.statementContainer {
  p {
    a {
      padding-left: 10px;
    }
  }
}
.uploadCard {
  .card {
    .content {
      padding: 0.5em 0.5em !important;
    }
  }
  .close {
    color: $dotRed;
    font-weight: 800;
    p {
      cursor: pointer;
    }
  }
  .attachmentDetails {
    width: 100%;
  }
  padding-left: 0 !important;
  padding-bottom: 14px !important;
}

.displayNone {
  display: none !important;
}
.uploadedParagraph {
  padding: 0 !important;
  margin-bottom: 0 !important;
  margin-top: 10px !important;
  color: $dotNavyBlue;
}
.all-documents {
  .input .ui.button {
    background-color: $dotOrrange !important;
    color: $dotWhite !important;
  }
}
.top10 {
  top: 10px !important;
}
.top100 {
  top: 100px !important;
}
.microphoneDiv {
  position: relative;
  z-index: 9;
  float: right;
  top: 40px;
    button {
    cursor: pointer;
    background: none;
    border: none;
  }
 
  .on button .icon {
    color: $dotMicBlue;
  }
  .off button .icon {
    color: $dotOrrange;
  }
  .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: $dotBlack;
    opacity: 0.5;
    color: $dotWhite;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 105%;
  }
}
.microphoneDiv:hover .tooltiptext {
  visibility: visible;
}

.positionRel {
  position: relative !important;
}
.speechDiv {
  width: 100%;
  position: absolute !important;
  // z-index: 999;
  right: 12px;
}
.textAreaPaddingRight {
  textarea {
    padding-right: 40px !important;
  }
  label {
    font-weight: 500 !important;
    font-size: 100% !important;
  }
}

.inner {
  overflow: hidden;
  width: 99%;
  position: relative;
  height: 100%;
  display: flex;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 0.28571429rem;
  .color {
    background: $dotOrrange;
    width: 20%;
    position: absolute;
    height: 100%;
    border-radius: 0.28571429rem;
  }
  .label {
    color: $dotNavyBlue;
    z-index: 499;
    position: relative;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    width: 100%;
    margin: 5px;
    font-weight: 700;
    font-size: 15px;
  }

  .percentLabel {
    // z-index: 499;
    position: relative;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    width: 100%;
    margin: 5px;
    font-weight: 700;
    font-size: 15px;
  }
}
.trst {
  color: $dotOrrange;
  // div > div > .css-1vr111p-option {

  // }
  div > div > .css-1qprcsu-option {
    background-color: $dotModalBorder;
    color: $dotOrrange !important ;
    input[type="checkbox"] {
      background-color: $dotOrrange !important;
      color: $dotOrrange !important;
    }
  }
  .css-11unzgr {
    height: 180px !important;
  }
}
.checkBoxHeight120 {
  .css-11unzgr {
    height: 120px !important;
  }
}
.warningError {
  border: 2px solid rgba(229, 44, 44, 0.4) !important;
}
.tableHover {
  .docType {
    display: table-cell !important;
  }
  .id_sr {
    display: table-cell !important;
  }
}

.extra-padding25 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}
.titleWraperExit {
  .container-height-width {
    padding-bottom: 0px !important;
  }
}

.exitCount{
  margin-top: 15px!important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.exitCountInput{
min-width:10em !important;
}
.export-btn{
margin-top: 20px!important;
margin-bottom: 20px!important;

}

.editor-input{
    margin-top: 5;
    border: 1px solid #d8dbe0;
    border-radius: 5;
    min-height: 250px;
    width: 100%;
}
.editor-error{
  margin-top: 5; 
   border: 1px solid #f44444;
  border-radius: 5;
  min-height: 250px;
  width: 100%;
}
.editorWrapper{
  margin-top: 30px;
   margin-bottom: 30px;
}