.dot-card {
  box-shadow: 0px 3px 6px #00000017 !important;
  width: auto !important;
  height: 100px;
  .content {
    overflow-y: hidden !important;
    overflow-x: hidden !important;
  }
}

.card-header {
  text-align: left;
  font: normal normal medium 14px/18px QuicksandMedium;
  letter-spacing: 0px;
  color: #193256 !important;
  opacity: 1;
  font-weight: 600 !important;
  // font-size: 18px !important;
  font-size: 100% !important;
  line-height: 24px !important;
  font-family: QuicksandMedium !important;
}

.card-description {
  display: flex;
  justify-content: space-between;
  align-items: center;

  // img {
  //     width: 48px !important;
  //     height: 48px !important;
  // }

  img {
    width: 25% !important;
    // height: 25% !important;
    @include device-md {
      width: 14% !important;
    }
  }

  .card-value {
    text-align: left;
    font: normal normal bold 30px/30px Quicksand;
    font-weight: bold !important;
    font-size: 30px !important;
    line-height: 30px !important;
    font-family: Quicksand !important;
    letter-spacing: 0px;
    color: #193256;
    opacity: 1;
  }
}

.appraisalCard {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 48px !important;
    height: 48px !important;
  }

  .card-value {
    text-align: left;
    font: normal normal bold 30px/30px Quicksand;
    font-weight: bold !important;
    font-size: 30px !important;
    line-height: 30px !important;
    font-family: Quicksand !important;
    letter-spacing: 0px;
    color: #193256;
    opacity: 1;
  }
}

.notificationDiv {
  .blue {
    -moz-box-shadow: -5px 0px 0px 0px $dotOrrange !important;
    -webkit-box-shadow: -5px 0px 0px 0px $dotOrrange !important;
    box-shadow: -5px 0px 0px 0px $dotOrrange !important;
    .content:first-child {
      display: inline-flex;
      .close {
        color: $dotRed;
        font-weight: 800;
        margin-top: 4px;
        margin-left: 61%;
      }
    }
  }
  .content {
    display: inline-flex;
    padding: 0.5em 0.5em !important;
    .close {
      cursor: pointer;
      color: $dotRed;
      font-weight: 800;
      margin-left: 56%;
    }
    p {
      margin: 0 0 0em !important;
      font-size: 12px;
      color: $dotBlack;
    }
  }
  .notificationInnerDiv {
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5 !important;
    border-radius: 5px !important;
    .ui.card > .extra,
    .ui.cards > .card > .extra {
      padding: 0.5em 0.5em !important;
    }
  }
  .ui.card,
  .ui.cards > .card {
    width: 100% !important;
  }
}
.notificationRow {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.notificationHeader {
  .column {
    display: inline-flex !important;
    gap: 35% !important;
  }
  p {
    cursor: pointer;
    color: $dotNavyBlue;
    margin: 0 0 0em !important;
  }
}

// .dashboardCard{

// }
